
import { ClientController } from '@/controller';
import { Button, IconButton, Switch,} from '@/ui/index';
import { defineComponent } from 'vue';
import store from "@/store/index";

export default defineComponent({
    name: "ClientForm",
    components: { Button, IconButton, Switch },
    data() {
        return{
            store,
            editMode: false,
            form:{
                uid: 0,
                custName: "",
                number: "",
                active: false
            }
        }
    },
    computed:{
        getProfileImageSrc() {
            return "";
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.handleGetClient(this.$route.params.id);
        } else {
            this.editMode = false;
        }
    },
    methods: {
        handleGoBack() {
            this.$router.go(-1);
        },
        handleActivateClient(value : any) {
            console.log("active", value)
        },
        handleSubmitPrevent(){
            if (this.editMode) {
                this.handleSave();
            } else {
                this.handleCreate();
            }
        },
        async handleGetClient(uid:any) {
            const res = await ClientController.getClientById(uid);
            if (!res.error) {
                this.form = res.client;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleCreate() {
            const res = await ClientController.createClient(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.clientCreated"), { position: "top" });
                this.$router.push("/administration/client/detail/" + res.client.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleSave() {
            const res = await ClientController.updateClient(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.clientUpdated"), { position: "top" });
                this.$router.push("/administration/client/detail/" + res.client.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
