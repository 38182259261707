import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-clientform-container" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "timi-form-item col-6" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "timi-form-item col-6" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.clientNumber')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.numberExample'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.number) = $event))
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.form.number]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.name') + '/ ' + _ctx.$t('label.company')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('label.name') + '/ ' + _ctx.$t('label.company'),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.custName) = $event))
          }, null, 8, _hoisted_6), [
            [_vModelText, _ctx.form.custName]
          ])
        ]),
        (_ctx.editMode)
          ? (_openBlock(), _createBlock(_component_Switch, {
              key: 0,
              item: "formMode",
              label: "Active",
              value: _ctx.form.active,
              onOnChange: _ctx.handleActivateClient
            }, null, 8, ["value", "onOnChange"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (!_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            text: _ctx.$t('button.addClient'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleCreate
          }, null, 8, ["text", "onOnClick"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            text: _ctx.$t('button.save'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleSave
          }, null, 8, ["text", "onOnClick"]))
    ])
  ]))
}